body {
  overflow: scroll;
  font-family: Arial, Helvetica, sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #fff;
}

.video-preview {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100;
  --webkit-transform-style: preserve-3d;
}

.preview-canvas {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

.stats-panel {
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  bottom: 0px;
  color: white;
  z-index: 2000;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.stats-panel div {
  margin-left: 10px;
  margin-right: 10px;
}
